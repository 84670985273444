import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import store from '../redux/store';
import ApplicationService from '../services/ApplicationService';

const PrivateRoute = ({ path, component: Component, ...rest }: any) => {
  const {
    config: { ConstructionPortalUrl, apiBaseUrl }
  } = store.getState();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [isError, setIsError] = useState<boolean>(false);

  const checkIsAuthenticated = useCallback(async () => {
    await ApplicationService.getUser('home')
      .then(() => {
        setIsAuthenticated(true);
      })
      .catch((err) => {
        if (err.response && err.response.status !== 401) {
          setIsAuthenticated(null);
          setIsError(true);
        } else setIsAuthenticated(false);
      });
  }, []);

  useEffect(() => {
    checkIsAuthenticated();
  }, [checkIsAuthenticated]);

  if (isAuthenticated !== null)
    return (
      <Route
        {...rest}
        render={({ location, ...props }) => {
          if (
            path === '/:projectName/portal' &&
            ConstructionPortalUrl.split(',').indexOf(document.referrer) ===
              -1 &&
              document.referrer !== decodeURIComponent(document.URL)
            //Request origin is not construction portal
          ) {
            return <Redirect to='/unAuthorized' />;
          } else if (
            (path === '/:projectName/portal' &&
              ConstructionPortalUrl.split(',').indexOf(document.referrer) >
                -1) || //First request from construction portal requires being passed to server
            !isAuthenticated
          ) {
            window.location.replace(`${apiBaseUrl}/login`);
            return null;
          } else return <Component {...props} />;
        }}
      />
    );
  else if (isError === true) return <h3>Something went wrong.</h3>;
  return null;
};

export default PrivateRoute;
